<div>
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></div>
    <p-inputNumber
        type="number"
        [formControl]="control"
        [ngClass]="{
            'p-inputtext-sm': size === 'sm',
            'p-inputtext-lg': size === 'lg',
            'w-100': block,
            'ng-invalid ng-dirty': control.touched && control.invalid
        }"
        [styleClass]="styleClass"
        [placeholder]="placeholder || ''"
        [useGrouping]="false"
        [min]="min ? min : -99999999999999"
        [max]="max ? max : 99999999999999"
        [minFractionDigits]="minFractionDigits ? minFractionDigits : 0"
        [maxFractionDigits]="maxFractionDigits ? maxFractionDigits : 0"
    ></p-inputNumber>
    <ng-container *ngIf="showErrors">
        <app-form-error *ngIf="control.touched" [errors]="control.errors" />
    </ng-container>
</div>
