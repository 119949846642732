<div *ngIf="divider === true">
    <p-divider layout="horizontal" type="solid" styleClass="py-4 m-0"></p-divider>
</div>
<div *ngIf="accordion === false; else accordionRef">
    <div class="header" [class.header-sm]="size === 'sm'">
        <h4 class="mt-0 pb-0 mb-0">{{ title }}<span *ngIf="required" class="required-star ms-1 fw-600 text-danger">*</span></h4>
    </div>
    <div class="pt-8 pb-6">
        <ng-container *ngTemplateOutlet="contentRef" />
    </div>
</div>

<ng-template #accordionRef>
    <div class="pt-6">
        <div
            (click)="onAccordionClick($event)"
            (keypress)="onAccordionClick($event)"
            tabindex="0"
            [class.header-sm]="size === 'sm'"
            class="d-flex align-items-center header header-accordion"
        >
            <h4 class="mt-0 pb-0 mb-0">{{ title }}<span *ngIf="required" class="required-star fs-14 ms-1 fw-600 text-danger">*</span></h4>
            <div class="ms-4" *ngIf="alert && (open$ | async) === false">
                <span appIcon="exclamation-triangle" color="danger" size="7"></span>
            </div>
            <div [class.rotate-180]="(open$ | async) === true" class="rotate-0 ms-auto">
                <span appIcon="chevron-down" color="default" size="7"></span>
            </div>
        </div>
        <div @Grow *ngIf="(open$ | async) === true" [@.disabled]="animationDisabled$ | async">
            <div class="pt-8 pb-6">
                <ng-container *ngTemplateOutlet="contentRef" />
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contentRef>
    <ng-content></ng-content>
</ng-template>
