import { Component, Input, OnInit, Output, EventEmitter, ViewChild, inject, OnDestroy } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';
import { BehaviorSubject, Subject, Subscription, delay, tap } from 'rxjs';

@Component({
    selector: 'app-form-chips',
    templateUrl: './form-chips.component.html',
    styleUrls: ['./form-chips.component.scss'],
})
export class FormChipsComponent implements OnInit, OnDestroy {
    private controlContainer = inject(ControlContainer);

    @Input() formControlGroup!: string;
    @Input() type = 'text';
    @Input() placeholder: string | null = null;
    @Input() label: string | null = null;
    @Input() block = false;
    @Input() size: 'md' | 'sm' | 'lg' = 'md';
    @Input() suggestions: { name: string; code: string }[] = [];
    @Output() enterEvent = new EventEmitter();
    @Output() enterBlur = new EventEmitter();
    @ViewChild('autoComplete') autoComplete!: AutoComplete;

    control!: FormControl;

    suggestionsFiltered$ = new BehaviorSubject<{ name: string; code: string }[]>([]);

    private readonly close$ = new Subject<void>();

    private readonly subscriptions$ = new Subscription();

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    ngOnInit() {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);

        this.suggestionsFiltered$.next(this.suggestions);

        this.subscriptions$.add(
            this.close$
                .pipe(
                    delay(100),
                    tap(() => {
                        this.autoComplete.hide(false);
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    filter(event: { query: string }) {
        const value = <{ name: string; code: string }[]>this.control.value;
        const suggestions = [...value, ...this.suggestions].filter((suggestion) => suggestion.name.toLowerCase().includes(event.query.toLowerCase()));
        const names = suggestions.map((suggestion) => suggestion.name.toLowerCase().trim());
        const term = event.query.toLowerCase().trim();

        if (term !== '' && !names.includes(term)) {
            suggestions.unshift({ name: event.query, code: event.query });
        }

        value.forEach((item) => {
            let index = suggestions.findIndex((suggestion) => suggestion.name === item.name);
            while (index !== -1) {
                suggestions.splice(index, 1);
                index = suggestions.findIndex((suggestion) => suggestion.name === item.name);
            }
        });

        this.suggestionsFiltered$.next(suggestions);
    }
}
