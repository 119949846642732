export enum FormErrorTypes {
    REQUIRED = 'required',
    WRONG_EMAIL = 'email',
    WRONG_PHONE_NUMBER = 'phone-number',
    WRONG_POSTAL_CODE = 'postal-code',
    MIN_LENGTH = 'minlength',
    MAX_LENGTH = 'maxlength',
    UNIQUE = 'unique',
    NIP = 'nip',
    PASSWORD = 'password',
    COMPARE = 'compare',
    NOT_SUPPORTED_COUNTRY = 'notSupportedCountry',
    CANT_BE_ZERO = 'min',
    CURRENCY_MUST_BE_SELECTED = 'currencyMustBeSelected',
    WORDS = 'words',
    MUST_MATCH = 'must-match',
    WORDS_AND_NUMBERS = 'words-numbers',
    TEXT = 'text',
    CUSTOMS = 'customs',
    GRACEFUL = 'graceful',
    INVALID_ADDRESS = 'invalidAddress',
    INVALID_STREET = 'invalidStreet',
    INVALID_STREET_NUMBER = 'invalidNumber',
    REGISTER_INVALID_EMAIL = 'registerInvalidEmail',
    INVALID_RETURN_DATE = 'invalidReturnDate',
    INVALID_LOOKUP_ADDRESS = 'invalidLookupId',
    MAX_VALUE = 'max',
    NUMBERS = 'numbers',
}
