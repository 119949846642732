import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appConvertToUppercase]',
})
export class ToUppercaseDirective {
    constructor(private el: ElementRef) {}

    @HostListener('input', ['$event']) onInputChanged(event: InputEvent): void {
        const value: string = this.el.nativeElement.value as string;
        this.el.nativeElement.value = value.toUpperCase();
        if (value !== this.el.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
