import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appPhoneNumber]',
})
export class PhoneNumberDirective {
    constructor(private el: ElementRef) {}

    @HostListener('input', ['$event']) onInputChanged(event: InputEvent): void {
        const initVal: string = this.el.nativeElement.value as string;
        this.el.nativeElement.value = initVal.replace(/[^0-9+]*/g, '');
        if (initVal !== this.el.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
