import { AbstractControl, ValidationErrors } from '@angular/forms';

export const DateRangeValidator = (control: AbstractControl): ValidationErrors | null => {
    if (Array.isArray(control.value)) {
        if (control.value[1] === null) {
            return { dateRange: true };
        }
        if (!isDate(control.value[0]) || !isDate(control.value[1])) {
            return { dateRange: true };
        }
    }
    return null;
};

const isDate = (value: Date) => {
    if (value instanceof Date && !isNaN(value.valueOf())) {
        return true;
    }
    return false;
};
