import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {
    @Input() formControlGroup!: string;
    @Input() type = 'text';
    @Input() placeholder: string | null = null;
    @Input() label: string | null = null;
    @Input() block = false;
    @Input() readonly = false;
    @Input() size = 'md';
    @Input() clearIcon = false;
    @Output() enterEvent = new EventEmitter();
    @Output() blurEvent = new EventEmitter();
    @Input() passwordInput = false;
    control!: FormControl;

    constructor(private controlContainer: ControlContainer) {}

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    ngOnInit() {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
    }

    clearFilter(event: MouseEvent) {
        event.preventDefault();
        this.control.setValue('');
        this.blurEvent.emit();
    }
}
