import { Component, Input, OnInit, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-form-multiselect',
    templateUrl: './form-multiselect.component.html',
    styleUrls: ['./form-multiselect.component.scss'],
})
export class FormMultiselectComponent implements OnInit {
    private controlContainer = inject(ControlContainer);
    private translateService = inject(TranslateService);

    @Input() formControlGroup!: string;
    @Input() placeholder: string | null = null;
    @Input() label: string | null = null;
    @Input() block = false;
    @Input() size = 'md';
    @Input() showHeader = false;
    @Input() filter = false;
    @Input() options: { label: string; value: string | number | boolean | null }[] = [];
    @Input() showClear = false;
    @Input() virtualScrollWidth: number | null = null;

    control!: FormControl;
    selectedOptions!: { label: string; value: string | number | null }[];

    count$!: Observable<string>;

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    get styleClass(): string {
        return [this.block ? 'w-100' : '', this.size === 'sm' ? 'p-inputtext-sm' : '', this.control.touched && this.control.invalid ? 'has-error' : '']
            .join(' ')
            .trim();
    }

    ngOnInit() {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
        this.count$ = this.control.valueChanges.pipe(
            map((items) => {
                return this.translateService.instant('forms.multiselect.x.selected', { value: `${items?.length ?? 0}` });
            }),
        );
    }
}
