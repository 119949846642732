import { Validators } from '@angular/forms';
import {
    MustMatchValidator,
    NipValidator,
    WordsValidator,
    WordsAndNumbersValidator,
    EmailValidator,
    PostalCodeValidator,
    TextValidator,
    StreetValidator,
    PhoneValidator,
    DateRangeValidator,
    DateValidator,
    AtleastOneValidator,
    NumbersValidator,
    ForeignNipValidator,
} from './customs';

export const FormValidators = {
    Nip: NipValidator,
    MustMatch: MustMatchValidator,
    Words: WordsValidator,
    WordsAndNumbers: WordsAndNumbersValidator,
    Email: EmailValidator,
    PostalCode: PostalCodeValidator,
    Text: TextValidator,
    Street: StreetValidator,
    MaxLength: Validators.maxLength,
    Required: Validators.required,
    RequiredTrue: Validators.requiredTrue,
    Phone: PhoneValidator,
    MinValue: Validators.min,
    MaxValue: Validators.max,
    DateRange: DateRangeValidator,
    Date: DateValidator,
    AtLeastOne: AtleastOneValidator,
    Numbers: NumbersValidator,
    ForeignNip: ForeignNipValidator,
};
