import { DOCUMENT } from '@angular/common';
import {
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    Renderer2,
    RendererFactory2,
    ViewContainerRef,
} from '@angular/core';
import { AppTooltipComponent } from '../components/app-tooltip.component';

@Directive({
    selector: '[appTooltip]',
})
export class AppTooltipDirective implements OnDestroy, OnChanges {
    @Input('appTooltip') tooltipText!: string;
    @Input() tooltipWidth?: string;
    private tooltipElement: ComponentRef<AppTooltipComponent> | null = null;
    left!: number;
    bottom!: number;
    constructor(
        private renderer: Renderer2,
        private el: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory2: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);
    }

    private showTooltip() {
        if (this.tooltipText && this.tooltipElement === null) {
            this.tooltipElement = this.viewContainerRef.createComponent(AppTooltipComponent);
            this.renderer.appendChild(this.document.querySelector('body'), this.tooltipElement.location.nativeElement);
            this.tooltipElement.instance.text = this.tooltipText;
            const elRect = this.el.nativeElement.getBoundingClientRect();
            this.bottom = window.innerHeight - (elRect.bottom + window.scrollY - elRect.height - 8);
            this.left = elRect.left + elRect.width / 2;
            const tooltipWidth = parseInt(this.tooltipWidth || '0', 10);
            if (this.left - tooltipWidth / 2 < 0) {
                this.left = tooltipWidth / 2;
            } else if (this.left + tooltipWidth / 2 > window.innerWidth) {
                this.left = window.innerWidth - tooltipWidth / 2 - 16;
            }

            this.renderer.setStyle(this.tooltipElement.location.nativeElement, 'bottom', `${this.bottom}px`);
            this.renderer.setStyle(this.tooltipElement.location.nativeElement, 'left', `${this.left}px`);
            this.renderer.setStyle(this.tooltipElement.location.nativeElement, 'position', 'absolute');

            if (this.tooltipWidth) {
                this.renderer.setStyle(this.tooltipElement.location.nativeElement, 'width', this.tooltipWidth);
            }
        }
    }

    private hideTooltip() {
        if (this.tooltipElement !== null) {
            this.tooltipElement.destroy();
            this.tooltipElement = null;
        }
    }

    ngOnChanges(): void {
        if (this.tooltipText && this.tooltipElement) {
            this.tooltipElement.instance.text = this.tooltipText;
        }
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.showTooltip();
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.hideTooltip();
    }

    ngOnDestroy() {
        this.hideTooltip();
    }
}
