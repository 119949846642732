import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    transform(items: { [key: string]: unknown }[], field: string, value: unknown): { [key: string]: unknown }[] {
        if (!items) return [];
        return items.filter((it) => it[field] == value);
    }
}
