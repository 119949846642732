import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import { FormErrorMsg } from '../../enums/form-error-msg.enum';

export const AtleastOneValidator = (control: AbstractControl): ValidationErrors | null => {
    const formArray = control as FormArray;
    let valid = false;
    let activeControl = null;
    formArray.controls.forEach((formGroup) => {
        if (formGroup.get('active') === null) {
            activeControl = formGroup.get('main');
        } else {
            activeControl = formGroup.get('active');
        }
        if (activeControl && activeControl.value === true) {
            valid = true;
        }
    });

    return valid ? null : { [FormErrorMsg.REQUIRED]: true };
};
