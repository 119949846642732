import { AbstractControl, ValidationErrors } from '@angular/forms';
import XRegExp from 'xregexp';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const ForeignNipValidator = (control: AbstractControl): ValidationErrors | null => {
    let value = <string>control.value || '';
    const pattern = "^[\\p{N}\\s\\-']+$";
    const firstTwoLetters = value ? value!.substring(0, 2).toUpperCase() : '';
    const isNotNumeric = /^[A-Za-z]{2}$/.test(firstTwoLetters);

    if (!isNotNumeric || firstTwoLetters === 'PL') {
        if (value.trim().toUpperCase() === 'PL' && value.length === 2) {
            return { [FormErrorTypes.NIP]: true };
        }

        if ('PL' === value.substring(0, 2).toUpperCase()) {
            value = value.substring(2);
        }
        if (value.trim() === '' && value !== '') {
            return { [FormErrorTypes.NIP]: true };
        }

        if (value !== '' && value.trim() !== '' && !XRegExp.test(value, XRegExp(pattern))) {
            return { [FormErrorTypes.NIP]: true };
        }

        if (value.length !== 10 && value !== '') {
            return { [FormErrorTypes.NIP]: true };
        }

        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        const numbers = value.split('');

        const partialSums = weights.map((weight, index) => {
            return weight * parseInt(numbers[index], 10);
        });

        const sum = partialSums.reduce((prev, curr) => (curr += prev), 0);
        const controlNumber = sum % 11;

        if (parseInt(numbers[9], 10) !== controlNumber && value !== '') {
            return { [FormErrorTypes.NIP]: true };
        }
    } else {
        return null;
    }

    return null;
};
