<div>
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></div>
    <textarea
        pInputTextarea
        [formControl]="control"
        [ngClass]="{
            'p-inputtext-sm': size === 'sm',
            'p-inputtext-lg': size === 'lg',
            'w-100': block,
            'has-error': control.touched && control.invalid
        }"
        [placeholder]="placeholder || ''"
        [autoResize]="false"
        autocomplete="off"
        [rows]="rows"
    ></textarea>
    <ng-container>
        <app-form-error *ngIf="control.touched" [errors]="control.errors" />
    </ng-container>
</div>
