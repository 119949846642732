import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
    selector: '[appScrollToInvalidInput]',
})
export class ScrollToInvalidInputDirective {
    @Input() ps?: PerfectScrollbar;
    constructor(
        private formGroupDir: FormGroupDirective,
        private el: ElementRef<HTMLElement>,
    ) {}

    @HostListener('ngSubmit') onSubmit(): void {
        if (this.formGroupDir.control.invalid) {
            this.scrollToFirstInvalidControl();
        }
    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('.ng-invalid')!;
        if (this.ps) {
            this.ps.element.scroll({
                top: this.getTopRelativeOffset(firstInvalidControl),
                left: 0,
            });
        } else {
            window.scroll({
                top: this.getTopOffset(firstInvalidControl),
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 70;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    private getTopRelativeOffset(controlEl: HTMLElement): number {
        const labelOffset = 70;
        return controlEl.offsetTop - labelOffset;
    }
}
