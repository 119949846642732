<div>
    <div *ngIf="title" class="tw-flex tw-items-center tw-mb-6">
        <div class="tw-mr-2">
            <h2 class="tw-text-3xl">{{ title }}</h2>
        </div>
        <div class="tw-ml-auto">
            <ng-content select="[actionButtons]"></ng-content>
        </div>
    </div>

    <div class="box" [class.tw-shadow-sm]="shadow" [class.box-border]="border" [class.tw-p-6]="padding" [class.tw-bg-white]="background">
        <ng-content></ng-content>
    </div>
</div>
