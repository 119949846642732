export enum FormErrorMsg {
    REQUIRED = 'FORMS.ERRORS.field_is_required_general',
    WRONG_EMAIL = 'FORMS.ERRORS.wrong_email',
    WRONG_PHONE_NUMBER = 'FORMS.ERRORS.wrong_phone_number',
    WRONG_POSTAL_CODE = 'FORMS.ERRORS.wrong_postal_code',
    MIN_LENGTH = 'FORMS.ERRORS.min_length',
    MAX_LENGTH = 'FORMS.ERRORS.max_length',
    UNIQUE = 'FORMS.ERRORS.unique',
    NIP = 'FORMS.ERRORS.nip',
    PASSWORD = 'FORMS.ERRORS.password',
    COMPARE = 'FORMS.ERRORS.compare',
    NOT_SUPPORTED_COUNTRY = 'FORMS.ERRORS.not_supported_country',
    CANT_BE_ZERO = 'FORMS.ERRORS.cant_be_zero',
    CURRENCY_MUST_BE_SELECTED = 'FORMS.ERRORS.currency_must_be_selected',
    INVALID_DATE = 'FORMS.ERRORS.invalid_date',
    WORDS = 'FORMS.ERRORS.words',
    MAX_VALUE = 'FORMS.ERRORS.max_value',
    TEXT = 'FORMS.ERRORS.text',
    NUMBERS = 'FORMS.ERRORS.numbers',
}
