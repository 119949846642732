import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';

@Component({
    selector: 'app-form-switch',
    templateUrl: './form-switch.component.html',
    styleUrls: ['./form-switch.component.scss'],
})
export class FormSwitchComponent implements OnInit {
    @Input() formControlGroup!: string;
    @Input() label: string | null = null;
    @Input() id?: string;
    @Input() trueValue: string | boolean = true;
    @Input() falseValue: string | boolean = false;
    @Output() changeEvent = new EventEmitter<InputSwitchChangeEvent>();

    control!: FormControl;

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit() {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
    }

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    get uuid() {
        return this.id ? this.id : this.formControlGroup;
    }
}
