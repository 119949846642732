import { AbstractControl, ValidationErrors } from '@angular/forms';

import XRegExp from 'xregexp';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const NumbersValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';
    const pattern = '^[0-9]+$';
    if (value !== '' && value.trim() !== '' && !XRegExp.test(value, XRegExp(pattern))) {
        return { [FormErrorTypes.NUMBERS]: true };
    }
    return null;
};
