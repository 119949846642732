import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOptions } from '@app/shared/models/multiselec-options.model';
import { PerfectScrollbarService } from '@app/shared/services/perfect-scrollbar.service';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'app-form-dropdown',
    templateUrl: './form-dropdown.component.html',
    styleUrls: ['./form-dropdown.component.scss'],
})
export class FormDropdownComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    private readonly controlContainer = inject(ControlContainer);
    private readonly perfectScrollbarService = inject(PerfectScrollbarService);

    @ViewChild('dropdown', { static: true }) dropdownRef!: Dropdown;
    @Input() formControlGroup!: string;
    @Input() placeholder: string | null = null;
    @Input() label: string | null = null;
    @Input() block = false;
    @Input() size = 'md';
    @Input() options: SelectOptions[] | null = null;
    @Input() showClear = false;
    @Input() showFilter = false;
    @Input() showErrors = true;
    @Input() appendTo: 'body' | null = 'body';
    @Input() editable = false;
    @Input() virtualScrollWidth: number | null = null;
    @Input() clearIfNotInOptions = false;

    @Output() changeEvent = new EventEmitter<DropdownChangeEvent>();
    @Output() clearEvent = new EventEmitter<Event>();

    control!: FormControl;

    private readonly subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
        if (this.clearIfNotInOptions && changes['options']?.currentValue) {
            const values = (<SelectOptions[]>changes['options']?.currentValue).map((item) => item.value);
            if (!values.includes(control.getRawValue())) {
                control.patchValue(null);
            }
        }
    }

    ngAfterViewInit(): void {
        this.subscriptions$.add(this.perfectScrollbarService.scrolling$.pipe(tap(() => this.dropdownRef.hide())).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    get styleClass(): string {
        return [this.block ? 'w-100' : '', this.size === 'sm' ? 'p-inputtext-sm' : '', this.control.touched && this.control.invalid ? 'has-error' : '']
            .join(' ')
            .trim();
    }

    get itemByValue() {
        return this.options?.find((option) => option.value === this.control.value) || null;
    }
}
