import { Injectable } from '@angular/core';
import { StorageModel } from '../models/storage.model';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public setItem(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    public getItem(item: string): StorageModel | null {
        try {
            return JSON.parse(<string>localStorage.getItem(item)) as { [key: string]: unknown };
        } catch (error) {
            return null;
        }
    }
}
