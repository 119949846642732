import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { OpenClose, PopupBaseComponent } from '@app/shared/popup';

interface InfoModalData {
    messageKey: string;
    buttonKey: string;
}

@Component({
    selector: 'app-info-popup',
    templateUrl: './info-popup.component.html',
    styleUrls: ['./info-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [OpenClose],
})
export class InfoPopupComponent extends PopupBaseComponent {
    @ViewChild('save') save!: ElementRef;

    override data!: InfoModalData;

    constructor(public override renderer2: Renderer2) {
        super(renderer2);
    }

    onConfirmClick(): void {
        this.close('confirmed');
    }
}
