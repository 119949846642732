import { Component, ChangeDetectionStrategy, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Grow } from '@app/shared/animations';
import { BehaviorSubject, Subscription, take, tap, timer } from 'rxjs';

@Component({
    selector: 'app-form-section',
    templateUrl: './form-section.component.html',
    styleUrls: ['./form-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Grow],
})
export class FormSectionComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() title!: string;
    @Input() required!: boolean;
    @Input() divider = false;
    @Input() accordion = false;
    @Input() alert = false;
    @Input() open = true;
    @Input() size: 'md' | 'sm' = 'md';

    open$ = new BehaviorSubject(false);
    animationDisabled$ = new BehaviorSubject(true);

    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.open$.next(this.open);
    }

    ngAfterViewInit() {
        this.subscriptions$.add(
            timer(0)
                .pipe(
                    tap(() => this.animationDisabled$.next(false)),
                    take(1),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    onAccordionClick(event: Event) {
        event.preventDefault();
        this.open$.next(!this.open$.value);
    }
}
