<div #block [class.block]="block">
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></div>
    <p-autoComplete
        #autoComplete
        [formControl]="control"
        [suggestions]="(suggestionsFiltered$ | async) ?? []"
        (completeMethod)="filter($event)"
        [multiple]="true"
        field="name"
        [forceSelection]="false"
        [dropdown]="true"
        [ngClass]="{
            'p-autocomplete-sm': size === 'sm',
            'p-autocomplete-lg': size === 'lg',
            'ng-invalid ng-dirty': control.touched && control.invalid
        }"
        styleClass="tw-w-full"
    >
        <ng-template let-item pTemplate="item">
            <div class="py-0 lh-1 fs-14">{{ item.name }}</div>
        </ng-template>
    </p-autoComplete>
    <ng-container>
        <app-form-error *ngIf="control.touched" [errors]="control.errors" />
    </ng-container>
</div>
