import { Pipe, PipeTransform, inject } from '@angular/core';
import { BookingAddressModel } from '@app/store/parcels';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'tooltipAddress',
})
export class TooltipAddress implements PipeTransform {
    private translateService = inject(TranslateService);
    transform(address: BookingAddressModel): string {
        const title = `<b>${this.translateService.instant('BOOKINGS.full_address')}</b>`;
        const address1 = (address.city?.trim() || '') + ' ' + (address.street?.trim() || '') + ' ' + (address.houseNumber || '');
        const address2 = (address.postalCode?.trim() || '') + ' ' + (address.country ? this.translateService.instant(`COUNTRY.${address.country}`) : '');

        const tooltip = `${title}<br/><span>${address1.trim() || '-'}</span><br/><span>${address2.trim() || '-'}</span>`;

        return tooltip;
    }
}
