import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-separator',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineComponent {
    @Input() align?: 'left' | 'center' | 'right' | 'top' | 'center' | 'bottom';
    @Input() layout: 'horizontal' | 'vertical' = 'horizontal';
    @Input() type: 'solid' | 'dashed' | 'dotted' = 'solid';
}
