import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-form-checkbox',
    templateUrl: './form-checkbox.component.html',
    styleUrls: ['./form-checkbox.component.scss'],
})
export class FormCheckboxComponent implements OnInit {
    @Input() formControlGroup!: string;
    @Input() label: string | null = null;

    control!: FormControl;

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
    }

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }
}
