import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-box',
    templateUrl: './box.component.html',
    styleUrls: ['./box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
    @Input() title?: string;
    @Input() padding = true;
    @Input() background = true;
    @Input() border = false;
    @Input() shadow = true;
}
