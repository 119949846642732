import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';

import { LineComponent } from './components/line/line.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { TrimWhitespaceDirective } from './directives/trim-whitespace.directive';
import { TagComponent } from './components/tag/tag.component';
import { ConvertPricePipe } from './pipes/convert-price.pipe';
import { CounterBadgeComponent } from './components/counter-badge/counter-badge.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CanShowElementDirective } from './directives/can-show-element.directive';
import { MessagesModule } from 'primeng/messages';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { ToUppercaseDirective } from './directives/to-uppercase.directive';
import { ScrollToInvalidInputDirective } from './directives/scroll-to-invalid-input.directive';
import { HideElementDirective } from './directives/hide-element.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryNamePipe } from './pipes/country-name.pipe';

import { BoxComponent } from './components/title-box/box.component';
import { IconsModule } from './icons/icons.module';
import { AppFormsModule } from './forms';
import { AppTooltipModule } from './tooltip/tooltip.module';
import { FluidHeightDirective } from '@shared/directives/fluid-height.directive';
import { ConfirmPopupComponent } from './popups/confirm-popup/confirm-popup.component';
import { InfoPopupComponent } from './popups/info-popup/info-popup.component';
import { TooltipAddress } from '@app/services/shared/pipes/address-tooltip.pipe';

// import { ShowElementDirective } from './table/show-element.directive';

const MODULES = [
    ToastModule,
    CardModule,
    ButtonModule,
    DividerModule,
    TagModule,
    TabViewModule,
    ConfirmDialogModule,
    TabMenuModule,
    MessagesModule,
    TooltipModule,
    AppFormsModule,
    IconsModule,
    AppTooltipModule,
];

const PIPES = [FilterPipe, ConvertPricePipe, CountryNamePipe, TooltipAddress];
const COMPONENTS = [LineComponent, TagComponent, CounterBadgeComponent, StatusIconComponent, BoxComponent, ConfirmPopupComponent, InfoPopupComponent];
const DIRECTIVES = [
    NumbersOnlyDirective,
    PhoneNumberDirective,
    TrimWhitespaceDirective,
    CanShowElementDirective,
    ToUppercaseDirective,
    ScrollToInvalidInputDirective,
    HideElementDirective,
    // ShowElementDirective,
    FluidHeightDirective,
];

@NgModule({
    imports: [CommonModule, TranslateModule.forChild(), RouterModule, FormsModule, ReactiveFormsModule, ...MODULES],
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    exports: [TranslateModule, RouterModule, ...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class SharedModule {
    static injector: Injector;

    constructor(injector: Injector) {
        SharedModule.injector = injector;
    }
}
