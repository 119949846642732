import { StorageModel } from '../models/storage.model';

export class LocalStorage {
    static write(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static remove(key: string): void {
        localStorage.removeItem(key);
    }

    static read(item: string): StorageModel | null {
        try {
            return JSON.parse(<string>localStorage.getItem(item)) as { [key: string]: unknown };
        } catch (error) {
            return null;
        }
    }
}
