import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { selectUserRole } from '@app/modules/user/store/selectors/user.selector';
import { Store } from '@ngrx/store';
import { Subscription, filter, tap } from 'rxjs';

@Directive({
    selector: '[appHideElement]',
})
export class HideElementDirective implements OnInit, OnDestroy {
    @Input('appHideElement') public ifRoles!: string[];

    private subscriptions$ = new Subscription();

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.subscriptions$.add(
            this.store
                .select(selectUserRole)
                .pipe(
                    filter((data): data is string => data !== undefined),
                    tap((data) => {
                        const appCanShowElement = !this.ifRoles.includes(data);
                        if (appCanShowElement) {
                            this.viewContainerRef.createEmbeddedView(this.templateRef);
                        } else {
                            this.viewContainerRef.clear();
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
