import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { PerfectScrollbarService } from '@app/shared/services';
import { Calendar } from 'primeng/calendar';
import { Subscription, filter, tap } from 'rxjs';

@Component({
    selector: 'app-form-datepicker',
    templateUrl: './form-datepicker.component.html',
    styleUrls: ['./form-datepicker.component.scss'],
})
export class FormDatepickerComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly controlContainer = inject(ControlContainer);
    private readonly perfectScrollbarService = inject(PerfectScrollbarService);

    @Input() formControlGroup!: string;
    @Input() placeholder: string | null = null;
    @Input() label: string | null = null;
    @Input() block = false;
    @Input() size = 'md';
    @Input() dateFormat = 'dd.mm.yy';
    @Input() selectionMode?: 'single' | 'multiple' | 'range' = 'single';
    @Input() showErrors = true;
    @Input() appendTo: 'body' | null = 'body';
    @ViewChild('calendar') calendarRef!: Calendar;
    @ViewChild('span') span!: ElementRef<HTMLSpanElement>;
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

    control!: FormControl;

    private subscriptions$ = new Subscription();

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    get styleClass(): string {
        return [this.block ? 'w-100' : '', this.size === 'sm' ? 'p-inputtext-sm' : '', this.control.touched && this.control.invalid ? 'has-error' : '']
            .join(' ')
            .trim();
    }

    ngOnInit() {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
    }

    ngAfterViewInit(): void {
        this.subscriptions$.add(
            this.perfectScrollbarService.scrolling$
                .pipe(
                    filter(() => this.calendarRef.focus === true),
                    tap(() => this.calendarRef.hideOverlay()),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onClose() {
        if (this.selectionMode === 'range') {
            if (Array.isArray(this.control.value) && this.control.value[1] === null) {
                this.control.patchValue([this.control.value[0], this.control.value[0]]);
            }
            if (this.control.value && this.control.value[1] !== null) {
                this.closeEvent.emit();
            }
        }
        if (this.selectionMode === 'single') {
            this.control.patchValue(this.control.value);

            if (this.control.value) {
                this.closeEvent.emit();
            }
        }

        this.span.nativeElement.focus();
        this.span.nativeElement.blur();
    }

    onSelect() {
        if (this.selectionMode === 'range' && this.control.value[1] !== null) this.calendarRef.hideOverlay();
    }

    onClear() {
        if (this.selectionMode === 'range') {
            this.control.patchValue([new Date(), new Date()]);
        }
        if (this.selectionMode === 'single') {
            this.control.patchValue(new Date());
        }
    }
}
