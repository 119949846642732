import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
    country = '';

    countries = [];

    constructor(private translateService: TranslateService) {}

    transform(value: string): string {
        return value ? this.translateService.instant(`COUNTRY.${value}`) : '';
    }
}
