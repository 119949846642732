import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const MustMatchValidator = (controlName: string, matchingControlName: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const v1: unknown = <string>control.value[controlName];
        const v2: unknown = <string>control.value[matchingControlName];

        if (v1 !== v2) {
            const errors = control.get(controlName)?.errors;
            control.get(controlName)?.setErrors({ ...errors, [FormErrorTypes.MUST_MATCH]: true });

            const errors2 = control.get(matchingControlName)?.errors;
            control.get(matchingControlName)?.setErrors({ ...errors2, [FormErrorTypes.MUST_MATCH]: true });

            return { [FormErrorTypes.MUST_MATCH]: true };
        }

        control.get(controlName)?.setErrors(null);
        control.get(matchingControlName)?.setErrors(null);

        return null;
    };
};
