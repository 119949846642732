import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PlanningModalCountService {
    /**
     * Count of Active Popups
     */
    count$ = new BehaviorSubject(0);

    /**
     * Add
     */
    add(): void {
        this.count$.next(this.count$.value + 1);
    }

    /**
     * Remove
     */
    remove(): void {
        this.count$.next(this.count$.value - 1);
    }
}
