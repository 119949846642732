import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appTrimWhitespace]',
})
export class TrimWhitespaceDirective {
    @HostListener('blur', ['$event.target', '$event.target.value'])
    onInputChanged(el: HTMLInputElement, value: string): void {
        el.value = value.trim();
        const event = document.createEvent('Event');
        event.initEvent('input', false, false);
        el.dispatchEvent(event);
    }
}
