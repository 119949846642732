import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertPrice',
})
export class ConvertPricePipe implements PipeTransform {
    transform(value: number | null): string {
        if (value === null) {
            return '---';
        }
        const [price, decimal] = value.toString().split('.');
        if (decimal?.length) {
            if (decimal.length === 2) {
                return `${price},${decimal}`;
            } else {
                return `${price},${decimal}0`;
            }
        } else {
            return `${price},00`;
        }
    }
}
