import { Component, HostBinding } from '@angular/core';
import { ShowHide } from '@app/shared/animations';

@Component({
    selector: 'app-tooltip',
    templateUrl: './app-tooltip.component.html',
    styleUrls: ['./app-tooltip.component.scss'],
    animations: [ShowHide],
})
export class AppTooltipComponent {
    text!: string;

    @HostBinding('@ShowHide') get() {
        return true;
    }
}
