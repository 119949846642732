import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconDirective } from './directive/icon.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [IconDirective],
    exports: [IconDirective],
})
export class IconsModule {}
