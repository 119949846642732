import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { OpenClose } from '../../animation/popup.animation';

@Component({
    selector: 'app-overlary',
    styleUrls: ['./overlay.component.scss'],
    templateUrl: './overlay.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [OpenClose],
})
export class OverlayComponent {
    @HostBinding('@openClose') get(): void {}
}
