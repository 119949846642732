<div>
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></div>
    <p-dropdown
        #dropdown
        (onChange)="changeEvent.emit($event)"
        (onClear)="clearEvent.emit($event)"
        appendTo="body"
        [formControl]="control"
        [options]="options ?? []"
        [styleClass]="styleClass"
        [panelStyle]="{ width: virtualScrollWidth ? virtualScrollWidth + 'rem' : 'auto', 'max-width': '100vw' }"
        [filter]="showFilter"
        [filterFields]="['label']"
        autocomplete="off"
        type="text"
        [placeholder]="placeholder || ''"
        [autoDisplayFirst]="false"
        [showClear]="showClear && itemByValue !== null"
        [baseZIndex]="3200"
        [ngClass]="{
            'ng-invalid ng-dirty': control.touched && control.invalid,
        }"
        [editable]="editable"
        emptyFilterMessage="Nie znaleziono wyników"
        emptyMessage="Brak wyników"
        [scrollHeight]="'250px'"
        [virtualScroll]="virtualScrollWidth !== null"
        [virtualScrollItemSize]="32"
        [virtualScrollOptions]="{
            autoSize: true,
            inline: true,
        }"
    >
        <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2 tw-h-[18px]">
                <div class="tw-truncate">
                    {{ item.label }}<span class="font-italic text-danger">{{ item.labelSuffix ? ' ' + item.labelSuffix : '' }}</span>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="itemByValue as itemByValue">
                <div class="tw-truncate">
                    {{ itemByValue.label }}<span class="font-italic text-danger">{{ itemByValue.labelSuffix ? ' ' + itemByValue.labelSuffix : '' }}</span>
                </div>
            </div>
        </ng-template>
    </p-dropdown>
    <ng-container>
        <div *ngIf="showErrors">
            <app-form-error *ngIf="control.touched && control.invalid" [errors]="control.errors" />
        </div>
    </ng-container>
</div>
