import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormInputComponent } from '../form-input.component';

@Component({
    selector: 'app-form-input-number',
    templateUrl: './form-input-number.component.html',
    styleUrls: ['./form-input-number.component.scss'],
})
export class FormInputNumberComponent extends FormInputComponent {
    @Input() override type = 'number';
    @Input() min = 0;
    @Input() max = 0;
    @Input() minFractionDigits = 0;
    @Input() maxFractionDigits = 0;
    @Input() showErrors = true;

    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

    get styleClass() {
        return [this.block ? 'w-100' : ''].filter((item) => item !== '').join(' ');
    }
}
