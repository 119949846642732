import { Injectable, ComponentRef, ApplicationRef, Renderer2, RendererFactory2, Type, Inject, createComponent, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, Subject, Subscription, filter, map, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import * as uuid from 'uuid';
import { PopupBaseComponent } from '../class/popup.class';
import { OverlayComponent } from '../components/overlay/overlay.component';

@Injectable({
    providedIn: 'root',
})
export class PopupService implements OnDestroy {
    /**
     * Closing Subject
     */
    closingSubject = new Subject<unknown>();

    /**
     * Simple Popup
     */
    private readonly popupComponentRefs: ComponentRef<PopupBaseComponent>[] = [];
    private readonly overlayComponentRefs: ComponentRef<OverlayComponent>[] = [];

    private renderer2: Renderer2;
    private subscriptions$ = new Subscription();

    constructor(
        private applicationRef: ApplicationRef,
        private rendererFactory2: RendererFactory2,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(null, null);
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationStart),
                    tap(() => this.closeAll('navigation-start')),
                )
                .subscribe(),
        );
    }

    /**
     * Opens popup with component, attaches it to ApplicationRef and renders at the end of <body>
     */
    popupId$ = new BehaviorSubject<string>('');
    open(compnent: Type<PopupBaseComponent>, data?: unknown, zIndex = 3050, id: string | null = null): void {
        const popupComponentRef = createComponent(compnent, {
            environmentInjector: this.applicationRef.injector,
        });

        popupComponentRef.instance.setData(id === null ? uuid.v4() : id, data, this.closeById);
        this.applicationRef.attachView(popupComponentRef.hostView);
        this.renderer2.appendChild(this.document.querySelector('body'), popupComponentRef.location.nativeElement);
        this.popupComponentRefs.push(popupComponentRef);

        if (this.popupComponentRefs.length === 1) {
            const overlayComponentRef = createComponent(OverlayComponent, {
                environmentInjector: this.applicationRef.injector,
            });
            this.applicationRef.attachView(overlayComponentRef.hostView);
            this.renderer2.setStyle(overlayComponentRef.location.nativeElement, 'z-index', zIndex);
            this.renderer2.appendChild(this.document.querySelector('body'), overlayComponentRef.location.nativeElement);
            this.overlayComponentRefs.push(overlayComponentRef);
        }
        this.popupId$.next(popupComponentRef.instance.uuid);
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.closeAll('destroy');
    }

    readonly closeById = (data: string, uuid: string | undefined): void => {
        const index = this.popupComponentRefs.findIndex((component) => component.instance.uuid === uuid);
        this.close(data, this.popupComponentRefs.splice(index, 1)[0]);
    };

    close(reason: string, component: ComponentRef<PopupBaseComponent>): void {
        this.renderer2.removeChild(this.document.querySelector('body'), component);
        component.destroy();

        if (this.popupComponentRefs.length === 0) {
            this.closeAllOverlay();
        }

        this.closingSubject.next(reason);
        this.popupId$.next('');
    }

    checkId$(id: string | null) {
        return this.popupId$.pipe(map((popupId) => popupId === id));
    }

    private closeAll(reason: string) {
        while (this.popupComponentRefs.length > 0) {
            this.close(reason, this.popupComponentRefs.splice(0, 1)[0]);
        }
    }

    private closeAllOverlay() {
        while (this.overlayComponentRefs.length > 0) {
            const component = this.overlayComponentRefs.splice(0, 1)[0];
            this.renderer2.removeChild(this.document.querySelector('body'), component);
            component.destroy();
        }
    }
}
