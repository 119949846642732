import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

type onChangeCallback = (value: string | number) => unknown;
type onTouchCallback = () => unknown;

@Component({
    selector: 'app-form-select-button',
    templateUrl: './form-select-button.component.html',
    styleUrls: ['./form-select-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormSelectButtonComponent),
            multi: true,
        },
    ],
})
export class FormSelectButtonComponent implements ControlValueAccessor, OnInit {
    @Input() formControlGroup!: string;
    @Input() label: string | null = null;
    @Input() block = false;
    @Input() size = 'md';
    @Input() options: { label: string; value: string | number }[] = [];

    ngControl!: NgControl;
    value$ = new BehaviorSubject<string | number | null>(null);

    constructor(private injector: Injector) {}

    ngOnInit() {
        this.ngControl = this.injector.get(NgControl);
    }

    writeValue(value: string): void {
        this.value$.next(value);
    }

    registerOnChange(fn: onChangeCallback): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: onTouchCallback): void {
        this.onTouch = fn;
    }

    onChange: onChangeCallback = (): void => {
        //
    };

    onTouch: onTouchCallback = (): void => {
        //
    };

    get required(): boolean {
        return this.ngControl.control?.hasValidator(Validators.required) || false;
    }

    onOptionClick(event: MouseEvent, value: string | number) {
        event.preventDefault();
        this.value$.next(value);
        this.onChange(value);
        this.onTouch();
    }
}
