import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { selectUserRole } from '@app/modules/user/store/selectors/user.selector';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';

@Directive({
    selector: '[appCanShowElement]',
})
export class CanShowElementDirective implements OnInit, OnDestroy {
    @Input('appCanShowElement') public ifRoles!: string[];

    private subscriptions$ = new Subscription();

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.subscriptions$.add(
            this.store
                .select(selectUserRole)
                .pipe(
                    tap((data) => {
                        const appCanShowElement = this.ifRoles.includes(<string>data);

                        if (appCanShowElement) {
                            this.viewContainerRef.createEmbeddedView(this.templateRef);
                        } else {
                            this.viewContainerRef.clear();
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
