import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { StatusTag } from '@app/shared/models/status-tag.model';

@Component({
    selector: 'app-status-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
    @Input() status!: StatusTag | null;
}
