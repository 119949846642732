export const environment = {
    production: false,
    sw: false,
    version: '2.0.0',
    serverUrl: 'https://api.sandbox.gtv.cloud.codelabs.work/bok/api/',
    defaultLanguage: 'pl-PL',
    supportedLanguages: ['pl-PL', 'en-EN'],
    integrations: {
        hereMaps: {
            apiKey: 'ialCS7t-of6XQ29Q7r5WNpM0B2yEV8dfoPS4E5kCuFA',
            routeUrl: 'https://router.hereapi.com/v8/routes',
            autosuggest: {
                center: [50.6673091, 17.8978411],
            },
        },
    },
    icons: 'assets/svgs/icons.svg',
    config: {
        hereMaps: {
            initialZoom: 6,
            initialLat: 51.76212,
            initilaLng: 19.36145,
            uiLanguage: 'pl-PL',
        },
    },
    planning: {
        transferTime: 300, // seconds
    },
    showVersion: 'all',
};
