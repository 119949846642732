import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const TextValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';
    if (value !== '' && value.trim() === '') {
        return { [FormErrorTypes.TEXT]: true };
    }
    return null;
};
