import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subscription, tap } from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
    selector: '[appFluidHeight]',
})
export class FluidHeightDirective implements AfterViewInit, OnDestroy {
    @Input({ required: true }) appFluidHeight!: Observable<void>;
    @Input() parentElement?: HTMLElement;
    @Input() margin = 20;

    private readonly domElement: HTMLElement;
    private subscriptions$ = new Subscription();
    private ps?: PerfectScrollbar;

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {
        this.domElement = this.elementRef.nativeElement as HTMLElement;
    }

    ngAfterViewInit(): void {
        this.ps = new PerfectScrollbar(this.domElement, { wheelPropagation: false });
        this.subscriptions$.add(this.appFluidHeight.pipe(tap(() => this.updateMaxHeight())).subscribe());
        this.updateMaxHeight();
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.ps?.destroy();
        delete this.ps;
    }

    private updateMaxHeight(): void {
        const windowHeight = window.innerHeight;
        const topOffset = this.getTopOffset();
        const calculatedMaxHeight = windowHeight - topOffset - this.margin;
        const maxHeight = calculatedMaxHeight < 80 ? 80 : calculatedMaxHeight > 600 ? 600 : calculatedMaxHeight;

        this.renderer.setStyle(this.domElement, 'max-height', `${maxHeight}px`);
        this.ps?.update();
    }

    private getTopOffset(): number {
        return (this.parentElement ?? this.domElement).getBoundingClientRect().top;
    }
}
