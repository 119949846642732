import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PaymentStatusTypes } from '@app/shared/enums/payment-status-types.enum';

@Component({
    selector: 'app-counter-badge',
    templateUrl: './counter-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterBadgeComponent {
    @Input() status!: string;
    @Input() set count(value: number) {
        this.value = value.toString();
    }

    get count(): number {
        return parseInt(this.value);
    }

    value!: string;

    get getSeverity() {
        if (this.status === PaymentStatusTypes.PENDING && this.count > 0) {
            return 'danger';
        }

        if (this.status === PaymentStatusTypes.PENDING && this.count === 0) {
            return 'info';
        }

        if (this.status === PaymentStatusTypes.COMPLETED && this.count > 0) {
            return 'success';
        }

        if (this.status === PaymentStatusTypes.COMPLETED && this.count === 0) {
            return 'info';
        }
        return 'info';
    }
}
