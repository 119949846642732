import { Renderer2, HostBinding, HostListener, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Popup Interface
 */
export declare interface Popup {
    saving?: boolean;
    uuid: string;

    /**
     * Passed Data
     */
    data: unknown;

    /**
     * Set Data
     */
    setData<T, D, R>(uuid: string, data: T, close: (data: D, uuid?: string) => R): void;
}

/**
 * Popup Absctract Class
 */
@Component({
    selector: 'app-planning-popup-base',
    template: '',
})
export abstract class PopupBaseComponent implements Popup, OnDestroy {
    blockEsc = false;
    uuid!: string;

    /**
     * Saving flag
     */
    saving?: boolean;

    /**
     * Data
     */
    data: unknown;

    /**
     * Subject
     */
    close!: <D, R>(data: D, uuid?: string) => R;

    constructor(public renderer2: Renderer2) {}

    /**
     * Animation host binding
     */
    @HostBinding('@openClose') get(): void {}

    private _subscriptions$ = new Subscription();

    /**
     * Listen to escape keypress - close if not saving
     */
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(): void {
        if (this.saving === true || this.blockEsc) {
            return;
        }
        this.close('key.escape');
    }

    ngOnDestroy(): void {
        this._subscriptions$.unsubscribe();
    }

    /**
     * Set Passed from parent data
     */
    setData<T, D, R>(uuid: string, data: T, close: (data: D, uuid?: string) => R): void {
        this.uuid = uuid;
        this.data = data;
        this.close<D, R> = close;
    }
}
