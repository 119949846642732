import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PaymentStatusModel } from '@app/store/bookings';

@Component({
    selector: 'app-status-icon',
    templateUrl: './status-icon.component.html',
    styleUrls: ['./status-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIconComponent {
    @Input() paymentResultStatuses!: PaymentStatusModel;
    @Input() rideStatus!: string | null;
    @Input() didntGo!: boolean;
}
