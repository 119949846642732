<div class="p-field">
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }} <span class="text-danger ms-1" *ngIf="required">*</span></div>
    <ng-container>
        <p-multiSelect
            [formControl]="control"
            [options]="options"
            [optionValue]="'value'"
            [optionLabel]="'label'"
            [filter]="filter"
            [styleClass]="styleClass"
            [panelStyle]="{ width: virtualScrollWidth ? virtualScrollWidth + 'rem' : 'auto', 'max-width': '100%' }"
            [placeholder]="placeholder || ''"
            [showHeader]="showHeader"
            [showClear]="showClear"
            [selectedItemsLabel]="(count$ | async) ?? ''"
            emptyFilterMessage="{{ 'FORMS.MESSAGES.multiselect_not_found' | translate }}"
            emptyMessage="{{ 'FORMS.MESSAGES.no-results' | translate }}"
            [scrollHeight]="'250px'"
            [virtualScroll]="virtualScrollWidth !== null"
            [virtualScrollItemSize]="32"
            [virtualScrollOptions]="{
                autoSize: true,
                inline: true,
            }"
        >
            virtualScrollWidth
            <ng-template let-item pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div class="tw-truncate">
                        {{ item.label }}<span class="font-italic text-danger">{{ item.labelSuffix ? ' ' + item.labelSuffix : '' }}</span>
                    </div>
                </div>
            </ng-template>
        </p-multiSelect>
    </ng-container>
    <ng-container>
        <app-form-error *ngIf="control.touched" [errors]="control.errors" />
    </ng-container>
</div>
