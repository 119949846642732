import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

import XRegExp from 'xregexp';

export const EmailValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';
    const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ((value.trim() !== value && value.trim() === '') || (!XRegExp.test(value.trim(), XRegExp(pattern)) && value !== '')) {
        return { [FormErrorTypes.WRONG_EMAIL]: true };
    }

    return null;
};
