<div>
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></div>
    <div class="btn-select btn-primary" [class.btn-select-block]="block">
        <button *ngFor="let option of options" [class.active]="(value$ | async) === option.value" (click)="onOptionClick($event, option.value)">
            {{ option.label }}
        </button>
    </div>
    <ng-container>
        <!-- <app-form-error *ngIf="control.touched" [errors]="control.errors" /> -->
    </ng-container>
</div>
