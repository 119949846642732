import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FormErrorMsg } from '../enums/form-error-msg.enum';
import { FormErrorTypes } from '../enums/form-error-types.enum';

@Component({
    selector: 'app-form-error',
    templateUrl: './form-error.component.html',
})
export class FormErrorComponent {
    @Input() errors!: ValidationErrors | null | undefined;

    param!: { [key: string]: unknown };

    get errorMsg(): string[] | undefined {
        if (this.errors) {
            return Object.keys(this.errors).map((error) => {
                switch (error) {
                    case FormErrorTypes.REQUIRED:
                        return FormErrorMsg.REQUIRED;
                    case FormErrorTypes.WRONG_EMAIL:
                        return FormErrorMsg.WRONG_EMAIL;
                    case FormErrorTypes.WRONG_PHONE_NUMBER:
                        return FormErrorMsg.WRONG_PHONE_NUMBER;
                    case FormErrorTypes.WRONG_POSTAL_CODE:
                        return FormErrorMsg.WRONG_POSTAL_CODE;
                    case FormErrorTypes.MIN_LENGTH:
                        this.param = { length: this.errors!['minlength'].requiredLength };
                        return FormErrorMsg.MIN_LENGTH;
                    case FormErrorTypes.MAX_LENGTH:
                        this.param = { length: this.errors!['maxlength'].requiredLength };
                        return FormErrorMsg.MAX_LENGTH;
                    case FormErrorTypes.UNIQUE:
                        return FormErrorMsg.UNIQUE;
                    case FormErrorTypes.NIP:
                        return FormErrorMsg.NIP;
                    case FormErrorTypes.PASSWORD:
                        return FormErrorMsg.PASSWORD;
                    case FormErrorTypes.COMPARE:
                        return FormErrorMsg.COMPARE;
                    case FormErrorTypes.NOT_SUPPORTED_COUNTRY:
                        return FormErrorMsg.NOT_SUPPORTED_COUNTRY;
                    case FormErrorTypes.CANT_BE_ZERO:
                        this.param = { length: this.errors!['min'].actual };
                        return FormErrorMsg.CANT_BE_ZERO;
                    case FormErrorTypes.CURRENCY_MUST_BE_SELECTED:
                        return FormErrorMsg.CURRENCY_MUST_BE_SELECTED;
                    case FormErrorTypes.WORDS:
                        return FormErrorMsg.WORDS;
                    case FormErrorTypes.WORDS_AND_NUMBERS:
                        return FormErrorMsg.WORDS;
                    case FormErrorTypes.MAX_VALUE:
                        return FormErrorMsg.MAX_VALUE;
                    case FormErrorTypes.TEXT:
                        return FormErrorMsg.TEXT;
                    case FormErrorTypes.NUMBERS:
                        return FormErrorMsg.NUMBERS;
                    case FormErrorTypes.GRACEFUL:
                    case FormErrorTypes.CUSTOMS:
                        return this.errors!['customs'];
                    default:
                        return error;
                }
            });
        }
        return undefined;
    }
    isArray(value: string | object) {
        return typeof value === 'string';
    }
}
