import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
    selector: '[appIcon]',
})
export class IconDirective implements OnInit {
    @Input()
    set appIcon(icon: string) {
        this.setIcon(icon);
        this.setClass(icon);
    }

    @Input()
    set size(size: string) {
        this.setSize(size);
    }

    @Input()
    set color(color: string) {
        this.setColor(color);
    }

    @Input()
    set status(status: 'none' | 'in' | 'out' | 'missing' | 'warning' | 'success' | null) {
        this.setStatus(status || null);
    }

    @Input()
    set count(count: string | number) {
        this.setCount(count || null);
    }

    @Input()
    set badge(badge: 'warning' | 'success' | 'danger' | 'info' | null) {
        this.setBadge(badge || null);
    }

    @Input()
    set badgeSize(badgeSize: 'lg' | null) {
        this.setBadgeSize(badgeSize || null);
    }

    private _appIcon = '';

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    get element() {
        return <HTMLElement>this.elementRef.nativeElement;
    }

    ngOnInit(): void {
        this.renderer.addClass(this.element, `icon`);
        this.renderer.setAttribute(this.element, 'title', '');
    }

    setIcon(icon: string) {
        if (icon !== this._appIcon) {
            this.element.innerHTML = `<svg><use xlink:href="${environment.icons}#${icon}" /></svg>`;
            this._appIcon = icon;
        }
    }

    setClass(icon: string) {
        this.renderer.removeClass(this.element, 'move-left');
        this.renderer.removeClass(this.element, 'move-right');
        if (icon === 'box-arrow-in-right') {
            this.renderer.addClass(this.element, 'move-left');
        }
        if (icon === 'box-arrow-right') {
            this.renderer.addClass(this.element, 'move-right');
        }
    }

    setCount(count: string | number | null) {
        this.renderer.removeAttribute(this.element, 'data-count');
        if (count !== null) {
            this.renderer.setAttribute(this.element, 'data-count', count.toString());
        }
    }

    setBadge(badge: 'warning' | 'success' | 'danger' | 'info' | null) {
        this.renderer.removeClass(this.element, 'badge');
        this.renderer.removeClass(this.element, 'badge-warning');
        this.renderer.removeClass(this.element, 'badge-success');
        this.renderer.removeClass(this.element, 'badge-danger');
        this.renderer.removeClass(this.element, 'badge-info');
        if (badge !== null) {
            this.renderer.addClass(this.element, 'badge');
            this.renderer.addClass(this.element, `badge-${badge}`);
        }
    }

    setBadgeSize(badge: 'lg' | null) {
        this.renderer.removeClass(this.element, 'badge-lg');
        if (badge !== null) {
            this.renderer.addClass(this.element, `badge-${badge}`);
        }
    }

    setStatus(status: string | null) {
        this.renderer.removeClass(this.element, 'status-none');
        this.renderer.removeClass(this.element, 'status-in');
        this.renderer.removeClass(this.element, 'status-out');
        this.renderer.removeClass(this.element, 'status-missing');
        this.renderer.removeClass(this.element, 'status-warning');
        this.renderer.removeClass(this.element, 'status-success');
        if (status !== null) {
            this.renderer.addClass(this.element, `status-${status}`);
        }
    }

    setSize(size: string) {
        this.renderer.removeClass(this.element, 'icon-1');
        this.renderer.removeClass(this.element, 'icon-2');
        this.renderer.removeClass(this.element, 'icon-3');
        this.renderer.removeClass(this.element, 'icon-4');
        this.renderer.removeClass(this.element, 'icon-5');
        this.renderer.removeClass(this.element, 'icon-6');
        this.renderer.removeClass(this.element, 'icon-7');
        this.renderer.removeClass(this.element, 'icon-8');
        this.renderer.removeClass(this.element, 'icon-9');
        this.renderer.removeClass(this.element, 'icon-10');
        this.renderer.removeClass(this.element, 'icon-11');
        this.renderer.removeClass(this.element, 'icon-12');
        this.renderer.removeClass(this.element, 'icon-13');
        this.renderer.removeClass(this.element, 'icon-14');
        this.renderer.addClass(this.element, `icon-${size}`);
    }

    setColor(color: string) {
        this.renderer.removeClass(this.element, 'icon-default');
        this.renderer.removeClass(this.element, 'icon-primary');
        this.renderer.removeClass(this.element, 'icon-secondary');
        this.renderer.removeClass(this.element, 'icon-success');
        this.renderer.removeClass(this.element, 'icon-danger');
        this.renderer.removeClass(this.element, 'icon-warning');
        this.renderer.removeClass(this.element, 'icon-info');
        this.renderer.removeClass(this.element, 'icon-muted');
        this.renderer.removeClass(this.element, 'icon-black');
        this.renderer.removeClass(this.element, 'icon-dark');
        this.renderer.removeClass(this.element, 'icon-darker');
        this.renderer.removeClass(this.element, 'icon-darkest');
        this.renderer.removeClass(this.element, 'icon-light');
        this.renderer.removeClass(this.element, 'icon-lighter');
        this.renderer.removeClass(this.element, 'icon-lightest');
        this.renderer.removeClass(this.element, 'icon-white');
        this.renderer.addClass(this.element, `icon-${color}`);
    }
}
