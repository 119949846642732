<div>
    <div class="d-flex align-items-center flex-nowrap">
        <p-inputSwitch
            *ngIf="control"
            [formControl]="control"
            [inputId]="uuid"
            [trueValue]="trueValue"
            [falseValue]="falseValue"
            (onChange)="changeEvent.emit($event)"
            class="text-none"
            [ngClass]="{
                'ng-invalid ng-dirty': control.touched && control.invalid
            }"
        ></p-inputSwitch>
        <label class="fs-16 lh-1 ms-4 mb-0 user-select-none" [for]="uuid" *ngIf="label"
            >{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></label
        >
    </div>
    <ng-container>
        <app-form-error *ngIf="control.touched" [errors]="control.errors" />
    </ng-container>
</div>
