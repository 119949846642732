<div class="box-popup">
    <div class="text-center">
        <span appIcon="exclamation-triangle" color="default" size="8" class="mb-10"></span>
    </div>
    <div class="title mb-12" translate>{{ data.messageKey | translate }}</div>
    <div class="row">
        <div class="col-12 flex-column">
            <p-button (click)="onConfirmClick()" #save class="p-button-primary w-100" label="{{ data.buttonKey | translate }}"></p-button>
        </div>
    </div>
</div>
