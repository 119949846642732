import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appNumbersOnly]',
})
export class NumbersOnlyDirective {
    @Input() p!: string;

    constructor(private el: ElementRef) {}

    @HostListener('input', ['$event']) onInputChanged(event: InputEvent): void {
        const regex = new RegExp(this.p, 'g');
        const initVal: string = this.el.nativeElement.value as string;
        this.el.nativeElement.value = initVal.replace(regex, '');
        if (initVal !== this.el.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
