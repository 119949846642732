<ng-container *ngIf="errors">
    <span *ngFor="let error of errorMsg" class="d-block text-danger fs-12">
        <span *ngIf="isArray(error); else elseBlock">
            {{ error | translate: param }}
        </span>
        <ng-template #elseBlock>
            <span *ngFor="let value of error" class="d-block text-danger fs-12">
                {{ value | translate: param }}
            </span>
        </ng-template>
    </span>
</ng-container>
