import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTooltipComponent } from './components/app-tooltip.component';
import { AppTooltipDirective } from './directives/app-tooltip.directive';

@NgModule({
    declarations: [AppTooltipComponent, AppTooltipDirective],
    imports: [CommonModule],
    exports: [AppTooltipDirective],
})
export class AppTooltipModule {}
