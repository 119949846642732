import { AbstractControl, ValidationErrors } from '@angular/forms';

import XRegExp from 'xregexp';
import { FormErrorTypes } from '../../enums/form-error-types.enum';

export const PostalCodeValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = <string>control.value || '';
    const pattern = "^[\\p{L}\\p{N}\\-']{4,10}$";
    if (value !== '' && value.trim() !== '' && !XRegExp.test(value, XRegExp(pattern))) {
        return { [FormErrorTypes.WRONG_POSTAL_CODE]: true };
    }
    if (value.trim() === '' && value !== '') {
        return { [FormErrorTypes.TEXT]: true };
    }
    return null;
};
