<div>
    <div class="d-flex align-items-center flex-nowrap">
        <p-checkbox *ngIf="control" [formControl]="control" [binary]="true" [inputId]="formControlGroup"></p-checkbox>
        <label class="fs-16 lh-1 ms-4 user-select-none" [for]="formControlGroup" *ngIf="label"
            >{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></label
        >
    </div>
    <ng-container>
        <app-form-error *ngIf="control.touched" [errors]="control.errors" />
    </ng-container>
</div>
