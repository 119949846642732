import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-form-textarea',
    templateUrl: './form-textarea.component.html',
    styleUrls: ['./form-textarea.component.scss'],
})
export class FormTextareaComponent implements OnInit {
    @Input() formControlGroup!: string;
    @Input() placeholder: string | null = null;
    @Input() label: string | null = null;
    @Input() block = false;
    @Input() size = 'md';
    @Input() rows!: number;
    control!: FormControl;

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit() {
        this.control = <FormControl>(<FormGroup>this.controlContainer.control).get(this.formControlGroup);
    }

    get required(): boolean {
        return this.control.hasValidator(Validators.required);
    }
}
