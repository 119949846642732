<div class="status">
    <div class="d-flex flex-nowrap">
        <div *ngIf="didntGo" class="border-end me-4 pe-4 border-gray-500 d-flex flex-nowrap gap-1">
            <span [class.item-resigned]="true" [appTooltip]="'<b class=\'text-danger\'>Nie pojechał</b>\n'" tooltipPosition="bottom" class="text-success">
                <i appIcon="car-front" size="6"></i
            ></span>
        </div>
        <ng-container *ngIf="paymentResultStatuses.mainPayment" [ngSwitch]="paymentResultStatuses.mainPayment.status">
            <!-- Completed -->
            <span
                *ngSwitchCase="'completed'"
                [class.item-resigned]="rideStatus === 'resigned' || paymentResultStatuses.mainPayment.allItemsResigned"
                [appTooltip]="(rideStatus === 'resigned' ? '<b class=\'text-danger\'>Rezygnacja</b>\n' : '') + ('TOOLTIP.service_completed' | translate)"
                tooltipPosition="bottom"
                class="text-success"
            >
                <i appIcon="check-circle" size="6" color="success"></i>
            </span>

            <!-- Pending -->
            <span
                *ngSwitchCase="'pending'"
                [class.item-resigned]="rideStatus === 'resigned' || paymentResultStatuses.mainPayment.allItemsResigned"
                [appTooltip]="(rideStatus === 'resigned' ? '<b class=\'text-danger\'>Rezygnacja</b>\n' : '') + ('TOOLTIP.service_pending' | translate)"
                tooltipPosition="bottom"
                class="text-warning"
            >
                <i appIcon="clock" size="6" color="warning"></i>
            </span>

            <!-- Unknwon -->

            <span
                *ngSwitchDefault
                [class.item-resigned]="rideStatus === 'resigned' || paymentResultStatuses.mainPayment.allItemsResigned"
                [appTooltip]="
                    (rideStatus === 'resigned' ? '<b class=\'text-danger\'>Rezygnacja</b>\n' : '') + ('TOOLTIP.payment_type_or_currency_unknown' | translate)
                "
                tooltipPosition="bottom"
                class="text-danger"
            >
                <i appIcon="question-circle" color="danger" size="6"></i>
            </span>
        </ng-container>

        <div *ngIf="paymentResultStatuses.additionalPayments.length > 0" class="ms-4 me-4 ps-4 border-start border-gray-500 d-flex flex-nowrap gap-1">
            <ng-container *ngFor="let additionalPayment of paymentResultStatuses.additionalPayments">
                <ng-container [ngSwitch]="additionalPayment.status">
                    <div *ngSwitchCase="'completed'">
                        <span
                            [class.item-resigned]="rideStatus === 'resigned' || additionalPayment.allItemsResigned"
                            [appTooltip]="
                                (rideStatus === 'resigned' || additionalPayment.allItemsResigned ? '<b class=\'text-danger\'>Rezygnacja</b>\n' : '') +
                                ('TOOLTIP.additional_service_completed' | translate)
                            "
                            tooltipPosition="bottom"
                            class="text-success"
                        >
                            <i appIcon="check-circle" size="6" color="success"></i>
                        </span>
                    </div>

                    <div *ngSwitchCase="'pending'">
                        <span
                            [class.item-resigned]="rideStatus === 'resigned' || additionalPayment.allItemsResigned"
                            [appTooltip]="
                                (rideStatus === 'resigned' || additionalPayment.allItemsResigned ? '<b class=\'text-danger\'>Rezygnacja</b>\n' : '') +
                                ('TOOLTIP.service_pending' | translate)
                            "
                            tooltipPosition="bottom"
                            class="text-warning"
                        >
                            <i appIcon="clock" size="6" color="warning"></i>
                        </span>
                    </div>

                    <div *ngSwitchDefault>
                        <span
                            [class.item-resigned]="rideStatus === 'resigned' || additionalPayment.allItemsResigned"
                            [appTooltip]="
                                (rideStatus === 'resigned' || additionalPayment.allItemsResigned ? '<b class=\'text-danger\'>Rezygnacja</b>\n' : '') +
                                ('TOOLTIP.payment_type_or_currency_unknown' | translate)
                            "
                            tooltipPosition="bottom"
                            class="text-danger"
                        >
                            <i appIcon="question-circle" color="danger" size="6"></i>
                        </span>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
