import { Injectable } from '@angular/core';
import { Subject, throttleTime } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PerfectScrollbarService {
    private _scrolling$ = new Subject<boolean>();
    scrolling$ = this._scrolling$.pipe(throttleTime(1000));
    scrollStart() {
        this._scrolling$.next(true);
    }
}
