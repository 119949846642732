import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { FormInputComponent } from './form-input/form-input.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormDropdownComponent } from './form-dropdown/form-dropdown.component';
import { FormSelectButtonComponent } from './form-select-button/form-select-button.component';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormSwitchComponent } from './form-switch/form-switch.component';
import { FormInputNumberComponent } from './form-input/form-input-number/form-input-number.component';
import { FormMultiselectComponent } from './form-multiselect/form-multiselect.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { FormChipsComponent } from './form-chips/form-chips.component';
import { IconsModule } from '../icons/icons.module';

@NgModule({
    declarations: [
        FormInputComponent,
        FormDropdownComponent,
        FormErrorComponent,
        FormSelectButtonComponent,
        FormDatepickerComponent,
        FormTextareaComponent,
        FormSwitchComponent,
        FormInputNumberComponent,
        FormCheckboxComponent,
        FormMultiselectComponent,
        FormSectionComponent,
        FormChipsComponent,
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        DropdownModule,
        InputTextModule,
        CheckboxModule,
        SelectButtonModule,
        CalendarModule,
        TranslateModule,
        InputTextareaModule,
        InputSwitchModule,
        InputNumberModule,
        MultiSelectModule,
        DividerModule,
        AutoCompleteModule,
        IconsModule,
    ],
    exports: [
        ReactiveFormsModule,
        FormInputComponent,
        FormDropdownComponent,
        FormSelectButtonComponent,
        FormDatepickerComponent,
        FormTextareaComponent,
        FormSwitchComponent,
        FormInputNumberComponent,
        FormCheckboxComponent,
        FormMultiselectComponent,
        FormSectionComponent,
        FormChipsComponent,
        FormErrorComponent,
    ],
})
export class AppFormsModule {}
